import logo from './logo.svg';
import './App.css';
import image_footer from './images.png';

function App() {
  return (
    <>

    <div className='container' style={{    backgroundColor: "currentcolor"}}>
    <nav class="navbar bg-light">
  <div class="container-fluid">
    <h4 class="navbar-brand" style={{    fontSize: "initial",
    textAlign: "center",
    fontWeight: "bold"}}>Top 10 websites for free Movies download</h4>
    <form class="d-flex" role="search">
     
    </form>
  </div>
</nav>
<section id="services" className="services section-bg">
      <div className="container" data-aos="fade-up">

        
      <table class="table table-dark table-striped">
      <thead>
    <tr>
      <th scope="col">Sno.</th>
      <th scope="col">Downloads Movies links</th>
      
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td><a href='https://themoviesverse.co/'>The Movies Verse</a></td>
      
    </tr>
    <tr>
      <th scope="row">2</th>
      <td><a href='https://www.moviesflix.website/'>Movie flix website</a></td>
     
    </tr>
    <tr>
      <th scope="row">3</th>
      <td colspan="2"><a href='https://filmyzilla.photography/'>Filmy zilla</a></td>
      
    </tr>
    <tr>
      <th scope="row">4</th>
      <td colspan="2"><a href='https://www40.filmymeet.co/'>Filmy meet</a></td>
      
    </tr>
    <tr>
      <th scope="row">4</th>
      <td colspan="2"><a href='https://filmy2wap.ga/'>Filmy 2 wap</a></td>
     
    </tr>
    <tr>
      <th scope="row">5</th>
      <td colspan="2"><a href='https://movies4u.gay/'>Movies4u</a></td>
     
    </tr>
    <tr>
      <th scope="row">6</th>
      <td colspan="2"><a href='https://hdmovie2.ink/'>Hdmovie2</a></td>
      
    </tr>
    <tr>
      <th scope="row">7</th>
      <td colspan="2"><a href='https://1movie.co/'>Movies watch online</a></td>
      
    </tr>
    <tr>
      <th scope="row">8</th>
      <td colspan="2"><a href='https://www.mp4moviez.mom/'>Mp4moviez</a></td>
      
    </tr>
   
    <tr>
      <th scope="row">9</th>
      <td colspan="2"><a href='https://desiremovies.homes/'>Desire movies</a></td>
      
    </tr>
    <tr>
      <th scope="row">10</th>
      <td colspan="2"><a href='https://khatrimaza.media/'>Khatarimaza</a></td>
      
    </tr>
  </tbody>
</table>
       
      </div>
    </section>

    <div class="card text-bg-dark">
  <img src={image_footer} style={{width:'auto',height:'auto'}} class="card-img" alt="..."/>
  <div class="card-img-overlay">
    
    <p class="card-text" style={{color:'lightgray'}}>This website developed by jd.....</p>
    
  </div>
</div>

</div>
    </>

  
  );
}


export default App;
